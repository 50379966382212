/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PackSectionDto } from './PackSectionDto';
import {
    PackSectionDtoFromJSON,
    PackSectionDtoFromJSONTyped,
    PackSectionDtoToJSON,
} from './PackSectionDto';

/**
 * 
 * @export
 * @interface GetResponsePackViewModel
 */
export interface GetResponsePackViewModel {
    /**
     * 
     * @type {string}
     * @memberof GetResponsePackViewModel
     */
    packName?: string | null;
    /**
     * 
     * @type {Array<PackSectionDto>}
     * @memberof GetResponsePackViewModel
     */
    packSections?: Array<PackSectionDto> | null;
}

/**
 * Check if a given object implements the GetResponsePackViewModel interface.
 */
export function instanceOfGetResponsePackViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetResponsePackViewModelFromJSON(json: any): GetResponsePackViewModel {
    return GetResponsePackViewModelFromJSONTyped(json, false);
}

export function GetResponsePackViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponsePackViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packName': !exists(json, 'packName') ? undefined : json['packName'],
        'packSections': !exists(json, 'packSections') ? undefined : (json['packSections'] === null ? null : (json['packSections'] as Array<any>).map(PackSectionDtoFromJSON)),
    };
}

export function GetResponsePackViewModelToJSON(value?: GetResponsePackViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packName': value.packName,
        'packSections': value.packSections === undefined ? undefined : (value.packSections === null ? null : (value.packSections as Array<any>).map(PackSectionDtoToJSON)),
    };
}

