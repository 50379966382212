<template>

    <div class="pagetitle">
        <h1>
            <font-awesome-icon icon="fa-regular fa-shop" />
            {{ signOffPackViewModel.storeName }} - {{ signOffPackViewModel.storeId}} 
        </h1>
        <nav>
            <ol class="breadcrumb">                
                <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Home</router-link></li>
                <li class="breadcrumb-item active">Sign Offs</li>
            </ol>
        </nav>
    </div>

    <ServerErrorDisplay :serverError="loadSectionsServerError"></ServerErrorDisplay>
    <div class="accordion" id="packAccordion" style="box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);">
        <div class="accordion-item" v-for="(section, index) in signOffPackViewModel.packSections" :key="index">
            <h2 class="accordion-header" :id="`heading${index}`">
                <button class="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" aria-expanded="false" :aria-controls="`collapse${index}`">
                    {{ section.sectionName }}<span class="badge rounded-pill bg-info text-dark ms-2">{{section.packs?.length}}</span>
                </button>
            </h2>
            <div :id="`collapse${index}`" class="accordion-collapse collapse" :aria-labelledby="`heading${index}`" data-bs-parent="#packAccordion">
                <div class="accordion-body p-0">
                    <div class="pack-check-list">
                        <div class="check-item-grid flo-pack-item" v-for="pack in section.packs" :key="pack.id">
                            <div class="description ps-3">
                                <div><font-awesome-icon icon="fa-regular fa-clipboard-check" /> {{ pack.packName }} <span v-show="pack.missed" class="badge bg-danger">Missed</span></div>                            
                                <div><font-awesome-icon icon="fa-regular fa-calendar" /> {{ pack.retailWeek }}</div>
                            </div>
                            <div class="icon check-action-icon" @click="onOpenSignOff(pack.id)" v-show="pack.allowSignOff">
                                <font-awesome-icon icon="fa-solid fa-chevron-right" size="2x" />
                            </div>
                        </div>
                    </div>                    
                </div>        
            </div>  
        </div>
    </div>

</template>

<script setup lang="ts">

    import { ref, onMounted } from 'vue'
    import { useRouter } from "vue-router";
    import { SignOffApi, type GetSignOffPacksViewModel } from '@/HttpClient';    
    import type ProblemDetails from "@/config/ProblemDetails";
    import { ApiConfig } from '@/config/apiConfig';    

    const api = new SignOffApi(new ApiConfig());    
    let signOffPackViewModel = ref<GetSignOffPacksViewModel>({});
    const router = useRouter();

    let loadSectionsServerError = ref<ProblemDetails>();

    function loadSections() {
        loadSectionsServerError.value = undefined;
        api.apiSignOffGet()
            .then((data) => {
                signOffPackViewModel.value = data;
            })
            .catch((error) => loadSectionsServerError.value = error);
    }

    function onOpenSignOff(packId : number | undefined) {
        router.push({name : 'signoffview', params : { id : packId}})
    }

    onMounted(() => {
        loadSections();
    })
    
</script>