<template>

    <div class="pagetitle">
        <h1>
            <font-awesome-icon icon="fa-regular fa-shop" />
            {{selectedStore.storeName }} - {{ selectedStore.storeId }}
        </h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Home</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Packs</router-link></li>
                <li class="breadcrumb-item active">{{ pack.packName }}</li>
            </ol>
        </nav>
    </div>

    <ServerErrorDisplay :serverError="loadingServerError"></ServerErrorDisplay>
    <div class="accordion" id="packAccordion" style="box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);">
        <PackSection v-for="(section, index) in packSections" :key="index" :pack-section="section" :isShown=" defaultSection == section.name" @check-selected="checkSelected" />
    </div>

        <ModalPopup  :iconColor="mapToIconStyle(selectedCheck.colour)" :icon="asString(selectedCheck?.icon)" :modelValue="showModal" :title="modalTitle" @update:model-value="closeModal">
            <template v-slot:default>
                <PackMissedResponse v-if="selectedCheck.isMissed" :reset="resetMissed" :isBatch="batchExplain" :missedCount="missedAtStartOfBatch" :completedCount="missedAtStartOfBatch - unexplainedMissedChecks.length" :check="selectedCheck" @explanation-changed="onMissedExplanationChanged"/>
                <PackResponse v-else :check="selectedCheck" :key="resetResponse" @responses-changed="responsesChanged"  @is-not-applicable-changed="isNotApplicableChanged"/>
                <ServerErrorDisplay :serverError="completeCheckServerError"></ServerErrorDisplay>
                <ServerErrorDisplay :serverError="missedCheckServerError"></ServerErrorDisplay>
            </template>
            <template v-slot:footer>
                <button class="btn btn-outline-dark" @click="closeModal">Close</button>
                <button v-if="selectedCheck.isMissed" class="btn btn-success" :disabled="preventCompletion" @click="onSubmitMissed">Submit</button>
                <button v-else class="btn btn-success" :disabled="preventCompletion" @click="onComplete">Complete</button>
            </template>
        </ModalPopup>

</template>


<script setup lang="ts">
    import PackResponse from '@/components/PackResponse.vue';
    import ModalPopup from '@/components/common/ModalPopup.vue';
    import PackSection from '@/components/PackSection.vue';
    import  PackMissedResponse from '@/components/PackMissedResponse.vue'
    import { onMounted, ref, computed } from 'vue';
    import {CheckResponseApi, ResponsePackApi, UserApi } from '@/HttpClient'
    import { ApiConfig } from '@/config/apiConfig';
    import type { PackCheckDto, GetResponsePackViewModel,  GetCurrentStoreInfoViewModel,  SupplementaryQuestionResponseDto, PackSectionDto , ResponseError} from '@/HttpClient'
    import { asString, asNumber } from '@/utils/nullable';
    import { mapToIconStyle } from '@/utils/styleMaps'
    import type ProblemDetails from "@/config/ProblemDetails";

    const requestApi = new ResponsePackApi(new ApiConfig());
    const userApi = new UserApi(new ApiConfig());

    interface Props {
        id: number
    }
    const props = defineProps<Props>()

    let pack = ref<GetResponsePackViewModel>({});
    let selectedCheck = ref<PackCheckDto>({})

    let selectedStore = ref<GetCurrentStoreInfoViewModel>({});

    let deferredCheck = ref<PackCheckDto>({})

    let loadingServerError = ref<ProblemDetails>();
    let completeCheckServerError = ref<ProblemDetails>();
    let missedCheckServerError = ref<ProblemDetails>();

    function loadComponentData(){
        loadingServerError.value = undefined;
        requestApi.apiResponsePackPackIdGet({id : props.id})
        .then((data) => {
            pack.value = data
            isNotApplicable.value = false;
            if(batchExplain.value && unexplainedMissedChecks.value.length > 0){
                responseIsValid.value = false;
                resetMissed.value++;
                selectedCheck.value = unexplainedMissedChecks.value[0];
                displayModal()
            }
            else if (batchExplain.value){
                batchExplain.value = false;
                responseIsValid.value = !((deferredCheck.value.supplementaryQuestions?.length ?? 0) > 0);
                resetResponse.value ++;
                selectedCheck.value = deferredCheck.value;
                displayModal();
            }
            else{
                resetMissed.value++;
                resetResponse.value++;
            }
        })
        .catch((error) => loadingServerError.value = error)

        userApi.apiUserCurrentStoreInfoGet()
        .then(response => selectedStore.value = response );
    }

    let packSections = computed(() => {
        return pack.value.packSections ?? []
    })

    onMounted(() => {
        loadComponentData();
    })

    const defaultSection = computed(() => {
        return 'Active'
    })

    // Modal 
    let responseIsValid = ref(false)

    let modalTitle = ref("")
    let showModal = ref(false)
  
    let resetMissed = ref(0);
    let resetResponse = ref(0);
    let isNotApplicable = ref(false);
    let responses = ref(new Array<SupplementaryQuestionResponseDto>);
    
    let missedExplanation = ref(0);

    let batchExplain = ref(false);
    let missedAtStartOfBatch = ref(0)
    let preventCompletion = computed(() => {
        return isNotApplicable.value ? false : !responseIsValid.value
    })

    let commandApi = new CheckResponseApi(new ApiConfig());
    function onComplete(){
        if (isNotApplicable.value || responseIsValid.value){   
            completeCheckServerError.value = undefined;
            let cmd = {                
                responseCheckId : selectedCheck.value.responsePackCheckId ?? 0,
                isNotApplicable : isNotApplicable.value,
                supplementaryQuestionResponses :responses.value
            }   
            commandApi.apiCheckResponseCompleteCheckPost({completeCheckCommand: cmd})
            .then(() => {
                loadComponentData();
                showModal.value = false;
            })
            .catch((error) => { missedCheckServerError.value = error;});
        }
    }

    function onSubmitMissed(){
        
        if(responseIsValid.value)
        {
            missedCheckServerError.value = undefined;
            let cmd = {
                checkResponseId : selectedCheck.value.responsePackCheckId,       
                explanationId : missedExplanation.value
            }
            commandApi.apiCheckResponseExplainMissedPost({addMissedReasonCommand : cmd})
            .then(() => {
                loadComponentData();
                if (!batchExplain.value) { showModal.value = false; }
            })
            .catch((error) => missedCheckServerError.value = error)            
        }
    }

    function closeModal() {
        selectedCheck.value = {}
        batchExplain.value = false;
        showModal.value = false
    }

    const unexplainedMissedChecks = computed(() => {
        let missed = pack.value.packSections?.find(s => s.name === 'Missed') as PackSectionDto;
        return missed.checks?.filter(c => !c.missedExplanation || c.missedExplanation.length == 0) ?? [] 
    })

    function checkSelected(selected: PackCheckDto) {
        let missed = pack.value.packSections?.find(s => s.name === 'Missed') as PackSectionDto;
        if(selected.isMissed || !missed.checks || unexplainedMissedChecks.value.length == 0 ){
            selectedCheck.value = selected
            responseIsValid.value = !selected.supplementaryQuestions?.length ?? 0 > 0 
            selected.supplementaryQuestions?.forEach(x => responses.value.push( {supplementaryQuestionResponseId: x.supplementaryQuestionId ?? 0, value: null }))   
            resetResponse.value++;
        }
        else{
            resetMissed.value++;
            deferredCheck.value = selected;
            selectedCheck.value  = unexplainedMissedChecks.value[0]
            batchExplain.value = true;
            missedAtStartOfBatch.value = unexplainedMissedChecks.value.length;
            responseIsValid.value = false;      
        }
        displayModal();
    }

    function displayModal(){
        completeCheckServerError.value = undefined;
        missedCheckServerError.value = undefined;
        modalTitle.value = `${selectedCheck.value.name}`  
        showModal.value = true; 
        responses.value = [];
        missedExplanation.value =0  
    }

    function onMissedExplanationChanged(value : number){
        missedExplanation.value = value;
        if(missedExplanation){
            responseIsValid.value = missedExplanation.value != 0 ;
        }
    }

    function responsesChanged(value : Array<SupplementaryQuestionResponseDto>){
        if(value.every(x => asNumber(x.value?.length) > 0  )){
            responseIsValid.value = true;
        }
        else{
            responseIsValid.value = false;
        }
        responses.value = value;
    }

    function isNotApplicableChanged(value : boolean){
        isNotApplicable.value = value;
    }

</script>
