<template>

    <HeaderNavigation @logout="logoutCalled" />
    
    <main id="main" class="main hasUpdated">
        <router-view></router-view>
       <!-- Healthcheck -->
        <!-- <HealthCheck /> -->
    </main>

</template>

<script setup lang="ts">

    // Vue Libraries
    import { RouterView } from "vue-router";
    import { onMounted,watch } from 'vue'

    // Components
    // import HealthCheck from "@/components/root/HealthCheckComponent.vue";
    import HeaderNavigation from "@/components/root/HeaderNavigationComponent.vue";

    // Stores & Services
    import AuthService from "@/services/application/authService";

    import {  useIdle } from '@vueuse/core'

    const authSvc = new AuthService();

    const { idle } = useIdle(10 * 60 * 1000) // 10 min

    watch(idle, (idleValue) => {
    if (idleValue) {
        logoutCalled();
    }
    })

    function logoutCalled() {
        authSvc.logout();
    }



</script>

