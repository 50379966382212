<template>

    <div class="pagetitle">
        <h1>
            <font-awesome-icon icon="fa-regular fa-shop" />
            {{ signOffPackViewModel.storeName }} - {{ signOffPackViewModel.storeId}}
        </h1>        
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Home</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{ name: 'signoffindex' }">Sign Offs</router-link></li>
                <li class="breadcrumb-item active">{{ signOffPackViewModel.packName }}</li>
            </ol>
        </nav>
    </div>

    <ServerErrorDisplay :serverError="loadPackServerError"></ServerErrorDisplay>

    <div class="card mb-2">
        <div class="card-body">
            <div>
                <div class="float-end">
                    <font-awesome-icon icon="fa-regular fa-calendar" />
                    {{ signOffPackViewModel.retailWeek }}
                </div>
                <font-awesome-icon icon="fa-regular fa-clipboard-check" />
                {{ signOffPackViewModel.packName }}
            </div>        
        </div>
    </div>

    <div class="card mb-2">
        <div class="card-body">
            <div><span class="badge rounded-pill bg-success">{{ signOffPackViewModel.passedCheckCount }}</span> Passed Checks</div>
            <div><span class="badge rounded-pill bg-warning text-dark">{{ signOffPackViewModel.notApplicableCheckCount }}</span> N/A Checks</div>
            <div><span class="badge rounded-pill bg-danger">{{ signOffPackViewModel.missedCheckCount }}</span> Missed Checks</div>            
        </div>
    </div>

    <div class="card mb-2" v-show="signOffPackViewModel.missedChecks?.length ?? 0 > 0">
        <div class="pack-check-list">
            <div class="check-item-grid flo-pack-item" :class="`flo-accent-${missedCheck.checkColour?.toLowerCase()}`" v-for="missedCheck in signOffPackViewModel.missedChecks" :key="missedCheck.id">
                <div class="description">  
                    <div><font-awesome-icon :icon="missedCheck.checkIcon" fixed-width class="pe-2" />{{ missedCheck.checkName }}</div>                    
                    <div><font-awesome-icon icon="fa-regular fa-clock" fixed-width class="pe-2" />Due by <span class="fw-bold">{{ formatTime(missedCheck.checkDueDate) }}</span> on <span class="fw-bold">{{ formatDate(missedCheck.checkDueDate) }}</span></div>
                    <div><font-awesome-icon icon="fa-regular fa-clipboard-question" fixed-width class="pe-2" />{{ missedCheck.missedReason }}</div>
                    <div v-if="signOffPackViewModel.hasStoreManagerSignoff">
                        <div><font-awesome-icon icon="fa-regular fa-circle-exclamation-check" fixed-width class="pe-2" />{{ missedCheck.correctiveActionDescription }}</div>                    
                    </div>
                    <div v-else>
                        <div><font-awesome-icon icon="fa-regular fa-user" fixed-width class="pe-2" />{{ missedCheck.missedUser }}</div>                    
                        <div class="mt-2">
                            <select class="form-select form-select-sm" v-model="missedCheck.correctiveAction">
                                <option v-for="ca in correctiveActionsViewModel.correctiveActions" :value="ca.id">{{ ca.description }}</option>                            
                            </select>
                        </div>                    
                    </div>                    
                </div>                
            </div>                       
        </div>
    </div>

    <div class="d-grid gap-2">
        <button class="btn btn-success" type="button" @click="onShowModal" :disabled="isSignOffDisabled">Sign Off</button>
        <button class="btn btn-secondary" type="button" @click="onCancel">Cancel</button>
    </div>

    <Modal :modelValue="showConfirmationModal" :title="`Sign Off`" @update:model-value="onCloseModal">
        <template v-slot:default>
            Are you sure you want to sign this off?
            <ServerErrorDisplay :serverError="signOffPackServerError"></ServerErrorDisplay>
        </template>
        <template v-slot:footer>
            <button class="btn btn-outline-dark" @click="onCloseModal">Cancel</button>
            <button class="btn btn-success" @click="onSignOffPack">Confirm</button>            
        </template>
    </Modal>

</template>

<script setup lang="ts">
    
    import { ref, onMounted, computed } from 'vue'
    import { useRouter } from "vue-router";
    import { SignOffApi, type GetSignOffPackByIdViewModel, type GetCorrectiveActionsViewModel, type CorrectiveActionResponseDto } from '@/HttpClient';
    import type ProblemDetails from "@/config/ProblemDetails";
    import { ApiConfig } from '@/config/apiConfig';    
    import { formatDate, formatTime } from '@/utils/dateFormat'    
    import Modal from '@/components/common/Modal.vue';

    interface Props {
        id: number
    }
    const props = defineProps<Props>()
    const api = new SignOffApi(new ApiConfig());    
    const router = useRouter();

    let signOffPackViewModel = ref<GetSignOffPackByIdViewModel>({});
    let correctiveActionsViewModel = ref<GetCorrectiveActionsViewModel>({});
    let showConfirmationModal = ref(false);

    let loadPackServerError = ref<ProblemDetails>();
    let signOffPackServerError = ref<ProblemDetails>();

    function loadPack() {
        loadPackServerError.value = undefined;
        api.apiSignOffIdGet({id : props.id})
            .then((data) => {
                signOffPackViewModel.value = data;
            })
            .catch((error) => loadPackServerError.value = error);
    }

    function loadCorrectiveActions() {
        api.apiSignOffCorrectiveActionsGet()
        .then((data) => {
            correctiveActionsViewModel.value = data;
            correctiveActionsViewModel.value.correctiveActions?.unshift({id: 0, description: 'Select corrective action...'})
        });
    }

    function onSignOffPack() {
        signOffPackServerError.value = undefined;
        if (signOffPackViewModel.value.hasStoreManagerSignoff) {
            let cmd = {                
                packId : props.id,    
            };

            api.apiSignOffAreaManagerPut({areaManagerSignOffCommand : cmd})
                .then(() => onCloseModal())    
                .then(() => router.push({name : 'signoffindex'}))    
                .catch((error) => signOffPackServerError.value = error)
        }
        else {
            let cmd = {                
                packId : props.id,
                correctiveActions : signOffPackViewModel.value.missedChecks?.map(mc => <CorrectiveActionResponseDto>{checkId : mc.id, correctiveActionId: mc.correctiveAction})
            };

            api.apiSignOffStoreManagerPut({storeManagerSignOffCommand : cmd})
                .then(() => onCloseModal())    
                .then(() => router.push({name : 'signoffindex'}))    
                .catch((error) => signOffPackServerError.value = error)
        }       
    }

    function onCancel() {
        router.push({name : 'signoffindex'});
    }
    
    function onShowModal() {
        signOffPackServerError.value = undefined;
        showConfirmationModal.value = true;
    }

    function onCloseModal() {
        showConfirmationModal.value = false;
    }

    let isSignOffDisabled = computed<boolean>(() => signOffPackViewModel.value.hasStoreManagerSignoff 
        ? false 
        : <boolean>signOffPackViewModel.value.missedChecks?.some(mc => mc.correctiveAction === 0));

    onMounted(() => {
        loadPack();
        loadCorrectiveActions();
    })
    
</script>