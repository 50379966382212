
import type { FetchParams, Middleware, ResponseContext } from "@/HttpClient"; 
import type ProblemDetails from "./ProblemDetails"


export class ApiMiddleware implements Middleware {
  public async pre(context: ResponseContext): Promise<FetchParams | void> {
    return {
      url: context.url,
      init: {
        ...context.init,
        headers: new Headers({
          ...context.init.headers
        }),
      },
    };
  }

  public async post(context: ResponseContext): Promise<Response | void> {
    
    if (!context.response.ok) {
      const error =  JSON.parse(await context.response.text()) || { message: context.response.statusText, status: context.response.status } as ProblemDetails;
      return Promise.reject(error);
    }
    return Promise.resolve(context.response);
  }


}

