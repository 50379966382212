/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PushNotificationSubscribeCommand,
  PushNotificationUnsubscribeCommand,
} from '../models/index';
import {
    PushNotificationSubscribeCommandFromJSON,
    PushNotificationSubscribeCommandToJSON,
    PushNotificationUnsubscribeCommandFromJSON,
    PushNotificationUnsubscribeCommandToJSON,
} from '../models/index';

export interface ApiNotificationNotifyPostRequest {
    storeId?: number;
    title?: string;
    message?: string;
}

export interface ApiNotificationSubscribePostRequest {
    pushNotificationSubscribeCommand?: PushNotificationSubscribeCommand;
}

export interface ApiNotificationUnsubscribePostRequest {
    pushNotificationUnsubscribeCommand?: PushNotificationUnsubscribeCommand;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     */
    async apiNotificationNotifyPostRaw(requestParameters: ApiNotificationNotifyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.storeId !== undefined) {
            queryParameters['storeId'] = requestParameters.storeId;
        }

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Notification/notify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationNotifyPost(requestParameters: ApiNotificationNotifyPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiNotificationNotifyPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationSubscribePostRaw(requestParameters: ApiNotificationSubscribePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Notification/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushNotificationSubscribeCommandToJSON(requestParameters.pushNotificationSubscribeCommand),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationSubscribePost(requestParameters: ApiNotificationSubscribePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiNotificationSubscribePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationUnsubscribePostRaw(requestParameters: ApiNotificationUnsubscribePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Notification/unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushNotificationUnsubscribeCommandToJSON(requestParameters.pushNotificationUnsubscribeCommand),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationUnsubscribePost(requestParameters: ApiNotificationUnsubscribePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiNotificationUnsubscribePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
