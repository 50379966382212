/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignOffPackDto
 */
export interface SignOffPackDto {
    /**
     * 
     * @type {number}
     * @memberof SignOffPackDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SignOffPackDto
     */
    packName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignOffPackDto
     */
    packDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SignOffPackDto
     */
    retailWeek?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SignOffPackDto
     */
    allowSignOff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignOffPackDto
     */
    missed?: boolean;
}

/**
 * Check if a given object implements the SignOffPackDto interface.
 */
export function instanceOfSignOffPackDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SignOffPackDtoFromJSON(json: any): SignOffPackDto {
    return SignOffPackDtoFromJSONTyped(json, false);
}

export function SignOffPackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignOffPackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'packName': !exists(json, 'packName') ? undefined : json['packName'],
        'packDescription': !exists(json, 'packDescription') ? undefined : json['packDescription'],
        'retailWeek': !exists(json, 'retailWeek') ? undefined : json['retailWeek'],
        'allowSignOff': !exists(json, 'allowSignOff') ? undefined : json['allowSignOff'],
        'missed': !exists(json, 'missed') ? undefined : json['missed'],
    };
}

export function SignOffPackDtoToJSON(value?: SignOffPackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'packName': value.packName,
        'packDescription': value.packDescription,
        'retailWeek': value.retailWeek,
        'allowSignOff': value.allowSignOff,
        'missed': value.missed,
    };
}

